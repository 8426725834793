<template>
  <div class="stipendiatContacts">
    <p v-if="getFakeUser" style="color: #eb722e; margin-bottom: 8px">{{ getFakeUser.name }}</p>
    <v-btn class="router_btn" @click="$router.back()">&#8592;</v-btn>
    <button class="usual-btn usual-btn_bold" @click="addContact">Добавить</button>
    <div class="stipendiatContacts__container">
      <div style="display: flex; flex-direction: row">
        <!--    основной контакт    -->
        <!--            :getContact="getContact"-->
        <ContactSimple
          :isMain="true"
          :editMode="contactsArray.length === 0 ? true : false"
          @getContact="getAll"
          @delete-data="deleteContact(mainContact.id)"
          :contactObject="mainContact"
          :contestTypesSpr="contestTypesSpr"
          :layoutTitle="'Контактная информация об ответственном исполнителе'" />
        <!--        <button-->
        <!--          v-if="contactsArray.length > 0"-->
        <!--          class="usual-btn usual-btn_delete" style="margin-left: 20px;"-->
        <!--          :disabled="otherContactsArray.length > 0" @click="deleteContact(mainContact.id)"-->
        <!--          :style="otherContactsArray.length > 0 ? 'color: #89A0B0; border: 1px solid #89A0B0; cursor: unset': ''"-->
        <!--        >Удалить-->
        <!--        </button>-->
      </div>
    </div>
    <!--    v-for второстепенных контактов    -->
    <!--            :getContact="getContact"-->
    <div class="stipendiatContacts__container">
      <div
        style="display: flex; flex-direction: row"
        v-for="(contact, j) in otherContactsArray"
        :key="j">
        <ContactSimple
          :isMain="false"
          @getContact="getAll"
          @delete-data="deleteContact(contact.id)"
          :contactObject="contact"
          :contestTypesSpr="contestTypesSpr"
          :layoutTitle="'Контактная информация об ответственном исполнителе'" />
        <!--        <button class="usual-btn usual-btn_delete" style="margin-left: 20px;" @click="deleteContact(contact.id)">-->
        <!--          Удалить-->
        <!--        </button>-->
      </div>
    </div>
    <!--    добавление нового второстепенного контакта    -->
    <!--            :getContact="getContact"-->
    <div class="stipendiatContacts__container" v-if="newContact">
      <ContactSimple
        :isMain="false"
        :editMode="true"
        @getContact="getAll"
        :contactObject="newContact"
        :contestTypesSpr="contestTypesSpr"
        :layoutTitle="'Контактная информация об ответственном исполнителе'" />
    </div>
  </div>
</template>

<script>
  import ContactSimple from '@/components/contacts/ContactSimple';
  import request from '@/services/request';
  import { mapActions, mapGetters } from 'vuex';
  import axios from 'axios';

  export default {
    name: 'Profile',
    components: { ContactSimple },
    data() {
      return {
        mainContact: {
          fio: '',
          email: '',
          position: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        },
        newContact: null,
        contactsArray: [],
        contestTypesSpr: [],
        otherContactsArray: [],
      };
    },

    computed: {
      ...mapGetters('keycloak', { getAuthData: 'getAuthData' }),
      ...mapGetters('keycloak', { getFakeUser: 'getFakeUser' }),
    },

    created() {
      this.getAll();
      this.getSpr();
    },

    methods: {
      ...mapActions('notifications', { addNotification: 'addNotification' }),
      ...mapActions('notifications', { clearNotifications: 'clearNotifications' }),

      async addContact() {
        this.newContact = {
          fio: '',
          email: '',
          position: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
          isMain: false,
        };
      },

      async getAll() {
        let uri = '';
        if (this.getFakeUser) {
          uri = `/api/cms/contacts/get?groupId=${this.getFakeUser.id}&groupName=orgID`;
        } else {
          uri = `/api/cms/contacts/get`;
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        await this.clearNotifications(); //Вызывается для очистки уведомления об ошибке получения данных
        if (res.status === 200 && res.data) {
          this.contactsArray = res.data;
          // console.log(res.data.filter(el => el.isMain === true));
          this.mainContact = res.data.filter((el) => el.isMain === true)[0];
          this.otherContactsArray = res.data.filter((el) => el.isMain === false);
          this.newContact = null;
        } else {
          if (res.status !== 404)
            await this.addNotification({
              notificationStatus: 'error',
              notificationMessage: 'Ошибка получения данных',
              // notificationMessage: 'Ошибка получения данных ответственного должностного лица высшего органа исполнительной власти',
              timeout: 5000,
            });
        }
      },

      async deleteContact(ID) {
        try {
          const res = await axios.delete(`/api/cms/contacts/delete?rowId=${ID}`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            await this.getAll();
            console.log('deleteContact успех');
          }
        } catch (e) {
          console.log(e);
        }
      },

      async getSpr() {
        try {
          const res = await axios.get(`/api/cms/contacts/getStpContestNameses`, {
            headers: { Authorization: `Bearer   ${this.getAuthData.token}` },
          });
          if (res.status === 200 && res.data) {
            this.contestTypesSpr = res.data.filter(el => el.id !== 2);
            for (let a = 0; a < this.contestTypesSpr.length; a++) {
              this.contestTypesSpr[a].value = null;
            }
            console.log('getSpr успех');
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .router_btn {
    height: 26px !important;
    padding: 0 8px !important;
    margin-bottom: 15px;
    width: 100px;
    cursor: url(../../assets/icons/cursor.svg), auto;
  }
</style>
